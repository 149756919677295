//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultLogger } from '@/loggers'
import { makeXHRequest } from '@/api/v3/xhr'
import { mapGetters } from 'vuex'
import { compareDates, scrollIntoViewIfNeeded } from '@/utils'
import { calendar } from '@/utils/DataUtils'
import ContactSelector from '@/components/UI/ContactSelector'
import DateTimeSelector from '@/components/UI/DateTimeSelector'
import Dropzone from '../../Default/FileUploader/Dropzone'
import FileThumbnail from './FileThumbnail'
import MemberPicker from '../MemberPicker'
import PopupMenuWrapper from '@/components/UI/Wrappers/PopupMenuWrapper'
import ScrollableArea from '@/components/UI/ScrollableArea'
import TagSelector from '@/components/UI/TagSelector'
import { format } from 'quasar'
import {
  remindsStore,
  sectionsStore,
  contactsStore,
  tasksStore,
  teamsStore,
  uiStore,
  uiSettingsStore,
} from '@/store'

const { capitalize } = format

export default {
  components: {
    ContactSelector,
    DateTimeSelector,
    Dropzone,
    FileThumbnail,
    IconAddCircle: () => import('@/components/UI/icons/IconAddCircle'),
    IconBell: () => import('@/components/UI/icons/IconBell'),
    IconCalendar: () => import('@tada/icons/dist/IconCalendar.vue'),
    IconQuestionCircle: () =>
      import('@/components/UI/icons/IconQuestionCircle'),
    IconTimes: () => import('@/components/UI/icons/IconTimes'),
    MemberPicker,
    PopupMenu: PopupMenuWrapper,
    ScrollableArea,
    TagSelector,
    ToggleSlider: () => import('@/components/UI/ToggleSlider.vue'),
  },
  data () {
    return {
      task: null,

      showDeadlineSelector: false,

      showRemindSelector: false,
      unsavedReminds: [],
      remindsLoading: false,
      remindsError: false,
      remindsErrorLabel: '',

      files: [],
      uploadingStates: [],
      createdChatId: null,
      filesNeedRetry: false,

      dragOver: false,
      contactDragOver: false,
      showAssigneeDropdown: true,

      inProcess: false,
      processReport: '',
      nameError: '',
      initialLoading: true,

      editMode: false,
      draftUpdateTimeout: null,
      draftHasChanges: false,
      createdFromDraft: false,

      uploadsShowMore: false,
      uploadsAspectRatio: 16 / 9,

      defaultAssignee: '',
    }
  },
  async created () {
    defaultLogger.debug('Task is in edit mode:', this.edit)
    let task
    if (this.edit) {
      task = { ...tasksStore.state.data[this.currentModalPayload.jid] }
    }

    const needToLoad = !task?.full || !this.edit
    if (needToLoad) {
      try {
        task = await this.loadTaskData()
        this.processReport = ''
        this.initialLoading = false
      } catch (e) {
        this.processReport = e.message || e.error
        // TODO: add sentry
      }
    }

    if (this.canUseDraft(task)) {
      this.task = { ...task, ...this.taskDraft }
      this.createdFromDraft = true
    } else {
      this.task = { ...task }
      const { description, assignee } = this.currentModalPayload || {}
      description && (this.task.description = description)
      assignee && (this.task.assignee = assignee)
    }

    this.defaultAssignee = this.task.assignee

    if (this.task.deadline?.toISOString) {
      this.task.deadline = this.task.deadline.toISOString()
    }

    this.initialLoading = false

    this.$nextTick(() => {
      const d = this.$refs.description
      d && d.focus()
    })
  },
  mounted () {
    this.$nextTick(() => {
      const d = this.$refs.description
      d && d.focus()
    })
  },
  async activated () {
    this.modalResult && this.updateSection(this.modalResult)

    uiStore.actions.setModalResult(null)

    this.$nextTick(() => {
      const d = this.$refs.description
      d && d.focus()
    })
  },
  computed: {
    ...mapGetters(['profileId']),
    modalResult () {
      return uiStore.getters.modalResult
    },
    currentModalPayload () {
      return uiStore.getters.currentModalPayload
    },
    uploadsToShow () {
      return this.uploadsShowMore
        ? this.task.uploads
        : this.task.uploads.slice(0, 4)
    },
    uploadsShowMoreBtn () {
      // do not show the button if
      // - there are no more uploads to see (all fit) AND
      // - uploads container is not in expanded state (allow hiding)
      if (
        this.uploadsToShow.length >= this.task.uploads.length &&
        !this.uploadsShowMore
      ) { return null }

      let label = ''
      if (this.uploadsShowMore) {
        label = capitalize(this.$t('common.hide'))
      } else {
        const numMore = this.task.uploads.length - this.uploadsToShow.length
        const caption = capitalize(this.$t('common.showMore'))
        label = `${caption} (+${numMore})`
      }

      const action = () => {
        this.uploadsShowMore = !this.uploadsShowMore
      }

      return { label, action }
    },
    deadlineQuickOptions () {
      return [
        {
          handler: () => {
            this.sendGoal('Дедлайн «Сегодня»')
            this.setDeadline({ day: 0 })
          },
          title: this.$t('tasks.deadline.today'),
        },
        {
          handler: () => {
            this.sendGoal('Дедлайн «Завтра»')
            this.setDeadline({ day: 1 })
          },
          title: this.$t('tasks.deadline.tomorrow'),
        },
        {
          handler: () => {
            this.sendGoal('Дедлайн «Неделя»')
            this.setDeadline({ day: 7 })
          },
          title: this.$t('tasks.deadline.week'),
        },
      ]
    },
    canEditReminders () {
      return this.task.canSendMessage
    },
    canBePublic () {
      return this.task.changeableFields.includes('public')
    },
    reminds () {
      const addFormattedDate = items =>
        items.map(item => ({
          ...item,
          text: calendar(new Date(item.fireAt), true),
        }))

      if (!this.task.jid) return addFormattedDate(this.unsavedReminds)

      const reminds = [
        ...(remindsStore.getters.remindsByChat[this.task.jid] ?? []),
        ...this.unsavedReminds,
      ]

      return addFormattedDate(reminds).sort((a, b) =>
        compareDates(a.fireAt, b.fireAt),
      )
    },
    edit () {
      return (
        !!this.currentModalPayload?.jid || !!this.currentModalPayload?.full?.jid
      )
    },
    formattedDeadline () {
      if (this.task.deadline === null) {
        let result = this.$t('tasks.deadline.no')
        if (this.canChange('deadline')) {
          result += ` ${this.$t('tasks.deadline.clickToSet')}`
        }
        return result
      }
      // let date = new Date(this.task.deadline)
      // let untilMidnight = date.getHours() === 23 && date.getMinutes() === 59
      // return untilMidnight ? dateFormat(date, 'dd.mm') : dateFormat(date, 'dd.mm, H:MM')
      return calendar(new Date(this.task.deadline), true)
    },
    creator () {
      return this.task.owner
        ? contactsStore.getters.contact(this.task.owner)
        : {}
    },
    canManageProjects () {
      return teamsStore.getters.currentTeam.me.canManageProjects
    },
    taskDraft () {
      return uiSettingsStore.getters.uiSettings.taskDraft
    },
    publicText () {
      return capitalize(this.$t('tasks.public'))
    },
    assigneeText () {
      return capitalize(this.$t('tasks.assignee'))
    },
    tagsText () {
      return capitalize(this.$t('tasks.tags'))
    },
    projectName () {
      return this.task.section
        ? sectionsStore.getters.section(this.task.section, 'task').name
        : this.$t('modals.NewTask.noProject')
    },
    statusName () {
      return tasksStore.getters.statusTitle(this.task.taskStatus ?? 'new')
    },
    hasExtraStatuses () {
      return tasksStore.getters.hasExtraStatuses
    },
  },
  methods: {
    openDeadlineSelector () {
      if (!this.canChange('deadline')) return

      this.sendGoal('Добавить «Кастомный» Дедлайн')
      this.showDeadlineSelector = true
    },
    updateDeadlineBySelector (v) {
      this.sendGoal(v ? '«Кастомный» Дедлайн добавлен' : 'Удалить дедлайн')
      this.task.deadline = v
    },
    closeDeadlineSelector () {
      this.showDeadlineSelector = false
    },
    openRemindSelector () {
      this.sendGoal('Добавить Напоминание')
      this.showRemindSelector = true
    },
    canUseDraft (task) {
      if (this.edit) {
        const useDraft = task.jid === this.taskDraft?.jid
        defaultLogger.debug(
          'Task is in edit mode.',
          'Draft and task jids are equal:',
          task.jid === this.taskDraft?.jid,
          'Using draft:',
          useDraft,
        )
        return useDraft
      }

      const { description, assignee, item } = this.currentModalPayload || {}
      const payloadEmpty =
        (!description || description.length === 0) && !assignee && !item

      const checkLinkedMessages = () => {
        const fromServer = task.linkedMessages
        const inDraft = this.taskDraft?.linkedMessages || []
        if (fromServer.length === 0 && inDraft.length === 0) return true
        if (fromServer.length !== inDraft.length) return false
        return fromServer.every(m1 =>
          inDraft.some(m2 => m1.messageId === m2.messageId),
        )
      }

      const useDraft =
        this.taskDraft &&
        !this.taskDraft.jid &&
        payloadEmpty &&
        checkLinkedMessages()

      defaultLogger.debug(
        'Task is not in edit mode, using draft:',
        useDraft,
        'because',
        'draft exists',
        this.taskDraft !== null,
        'draft was not in edit mode',
        !this.taskDraft?.jid,
        'modal payload emoty',
        payloadEmpty,
        'checkLinkedMessages',
        checkLinkedMessages(),
      )

      return useDraft
    },
    loadTaskData () {
      if (this.edit) {
        return tasksStore.actions.loadTask(this.currentModalPayload.jid)
      } else {
        const { linkedMessages, cloneJid, item } =
          this.currentModalPayload || {}
        return tasksStore.actions.loadDraft({ linkedMessages, cloneJid, item })
      }
    },
    async reloadWithoutDraft () {
      let task
      if (this.edit) {
        task = { ...tasksStore.state.data[this.currentModalPayload.jid] }
      }
      const needToLoad = !task?.full || !this.edit
      if (needToLoad) {
        this.initialLoading = true
        this.task = await this.loadTaskData()
        this.initialLoading = false
      } else this.task = { ...task }
      this.createdFromDraft = false
      this.deleteDraft()
    },
    deleteDraft () {
      uiSettingsStore.actions.updateTaskDraft(null)
    },
    updateTaskDraft () {
      this.draftHasChanges = true

      const update = () => {
        defaultLogger.debug('Task draft updater called...')
        if (!this.draftHasChanges) {
          defaultLogger.debug('no changes since last save, abort')
        } else {
          defaultLogger.debug('saving...', this.task.description)
          uiSettingsStore.actions.updateTaskDraft(this.task)
          this.draftHasChanges = false
        }
        this.draftUpdateTimeout = null
      }

      if (!this.draftUpdateTimeout) {
        defaultLogger.info('Timeout passed, updating task draft...')
        update()
        this.draftUpdateTimeout = setTimeout(update, 5000)
      } else {
        defaultLogger.debug(
          'Task draft update called too often, delaying...',
          this.draftUpdateTimeout,
        )
      }
    },
    updateDescription (v) {
      if (typeof v !== 'string' && !(v instanceof String)) return
      if (v === this.task?.description) return
      this.task.description = v
      this.updateTaskDraft()
    },
    updatePublic (v) {
      this.sendGoal((v ? 'ВКЛ' : 'ВЫКЛ') + ' «Публичность»')

      this.task.public = v
      this.updateTaskDraft()
    },
    updateSection (v) {
      this.task.section = v
      this.updateTaskDraft()
    },
    updateStatus (v) {
      this.task.taskStatus = v
      this.updateTaskDraft()
    },
    updateAssignee (v) {
      const lastAssignee = this.task.assignee
      const obs = this.task.observers

      if (lastAssignee === this.defaultAssignee && v !== this.defaultAssignee) this.sendGoal('Сменить Исполнителя')
      if (v === this.profileId) this.sendGoal('Сменить Исполнителя на себя')

      // add previous assignee to observers if it's not an owner
      if (lastAssignee !== this.task.owner && !obs.includes(lastAssignee)) {
        obs.push(lastAssignee)
      }

      // remove new assignee from observers if it's there
      const newAssIndex = obs.indexOf(v)
      if (newAssIndex !== -1) obs.splice(newAssIndex, 1)

      // this.updateObservers(obs)
      this.task.observers = obs
      this.task.assignee = v
      this.updateTaskDraft()
    },
    updateObservers (v) {
      if (v.length > 0) this.sendGoal('Выбрать участника')
      this.sendGoal2({ 'Количество участников': v.length })

      this.task.observers = v
      this.updateTaskDraft()
    },
    updateDeadline (v) {
      this.task.deadline = v
      this.updateTaskDraft()
    },
    updateTags (v) {
      if (v.length > this.task.tags.length) this.sendGoal('Добавить тег')
      if (v.length < this.task.tags.length) this.sendGoal('Удалить тег')
      this.sendGoal2({ 'Количество тегов': v.length })

      this.task.tags = v
      this.updateTaskDraft()
    },
    removeUpload (uid) {
      this.task.uploads = this.task.uploads.filter(u => u.uid !== uid)
      this.updateTaskDraft()
    },
    addProject () {
      this.sendGoal('Плюс «Проект»')

      uiStore.actions.setModalResult(null)

      uiStore.actions.showModal({
        instance: 'edit-section',
        payload: {
          uid: null,
          previousInstance: 'new-task',
          previousPayload: this.currentModalPayload,
        },
      })
    },
    projectOptions () {
      const options = [
        {
          key: 'none',
          name: this.$t('modals.NewTask.noProject'),
          action: () => {
            this.updateSection(null)
          },
        },
        { divider: true },
      ]

      // TODO: change to Object.values(sectionsStore.state.task).forEach(s => { if (s.isArchive) return; options.push(...
      for (const uid of Object.keys(sectionsStore.state.task)) {
        const s = sectionsStore.getters.section(uid, 'task')
        s.isArchive ||
          options.push({
            key: uid,
            name: s.name,
            // active: this.task.section === uid,
            action: () => {
              this.sendGoal('Выбрать «Проект»')
              this.updateSection(uid)
            },
          })
      }

      return options
    },
    statusOptions () {
      return tasksStore.state.statuses.map(status => ({
        key: status.uid,
        name: status.title,
        action: () => {
          this.sendGoal('Выбрать «Статус»')
          this.updateStatus(status.name)
        },
      }))
    },
    retryRemind () {
      this.retryRemindFn()
    },
    async addRemindBySelector (date) {
      if (!date || this.unsavedReminds.includes(date)) return
      this.sendGoal('Напоминание добавлено')
      await this.addRemind(date)
    },
    async addRemind (date) {
      if (!date) return
      !this.unsavedReminds.includes(date) &&
        this.unsavedReminds.push({ fireAt: date })
    },
    async removeRemindByUser (val) {
      this.sendGoal('Удалить напоминание КРЕСТИК')
      await this.removeRemind(val)
    },
    async removeRemind (val) {
      // val: uid | date
      if (this.unsavedReminds.some(r => r.fireAt === val)) {
        this.unsavedReminds = this.unsavedReminds.filter(r => r.fireAt !== val)
        return
      }
      this.remindsError = false
      this.remindsLoading = true
      try {
        remindsStore.actions.delete(val)
      } catch (e) {
        console.error('Remove remind error', e)
        this.remindsError = true
        this.retryRemindFn = () => this.removeRemind(val)
      }
      this.remindsLoading = false
    },
    getClassFromMember (member) {
      return member.jid === this.task.assignee ? 'item--assignee' : undefined
    },
    contactDragStart (jid, event) {
      event.dataTransfer.effectAllowed = 'link'
      event.dataTransfer.setData('tadateam/x-contact', jid)
      event.dataTransfer.setDragImage(event.target, 50, 14)
      return true
    },
    contactDragEnter (event) {
      this.contactDragLastTarget = event.target
      this.contactDragOver =
        event.dataTransfer.types.indexOf('tadateam/x-contact') !== -1
    },
    contactDragLeave (event) {
      try {
        if (event.target !== this.contactDragLastTarget) return
      } catch (e) {
        console.warn(`[NewTask.contactDragLeave err]: ${e}`)
      }
      this.contactDragOver = false
    },
    contactOnDragOver (e) {
      if (!this.contactDragOver) {
        return true
      }

      if (e.preventDefault) {
        e.preventDefault()
      }

      e.dataTransfer.dropEffect = 'link'

      return false
    },
    async contactDrop (event) {
      this.contactDragOver = false

      const jid = event.dataTransfer.getData('tadateam/x-contact')
      this.task.assignee = jid
      this.showAssigneeDropdown = true
    },
    dragEnter (event) {
      this.lastTarget = event.target
      if (event.dataTransfer.types.indexOf('tadateam/x-contact') !== -1) {
        return
      }
      this.dragOver = true
    },
    dragLeave (event) {
      try {
        if (event.target !== this.lastTarget) return
      } catch (e) {
        console.warn(`[NewTask err]: ${e}`)
      }
      this.dragOver = false
    },
    async drop (event) {
      this.dragOver = false

      const { files } = event.dataTransfer
      console.log(files, this.$refs.dropzone)
      this.$refs.dropzone && this.$refs.dropzone.handleFiles(files)

      await this.$nextTick()
      scrollIntoViewIfNeeded(this.$refs.filesBlock, 'scrollable-content')
    },
    removeFile (index) {
      this.files.splice(index, 1)
    },
    canChange (field) {
      return this.task.changeableFields.includes(field)
    },
    setDeadline ({ day }) {
      const deadline = new Date()
      if (this.task.deadline) {
        const oldDeadline = new Date(this.task.deadline)
        deadline.setHours(oldDeadline.getHours())
        deadline.setMinutes(oldDeadline.getMinutes())
      } else {
        const time = (
          teamsStore.getters.currentTeam.defaultTaskDeadline || ''
        ).split(':')
        deadline.setHours(time[0] ? +time[0] : 23)
        deadline.setMinutes(time[1] ? +time[1] : 59)
      }
      deadline.setDate(deadline.getDate() + day)
      this.updateDeadline(deadline.toISOString())
    },
    report (message) {
      this.processReport = message
      this.inProcess = false
    },
    cancel (deleteDraft = false) {
      this.sendGoal('Кнопка «Отменить»')
      this.close(deleteDraft)
    },
    close (deleteDraft = false) {
      deleteDraft && this.deleteDraft()
      uiStore.actions.hideModal()
    },
    async submit () {
      this.sendGoal(this.edit ? 'Кнопка «Сохранить»' : 'Кнопка «Создать»')

      if (this.edit && this.unsavedReminds.length) {
        this.remindsError = false
        this.remindsLoading = true
        this.unsavedReminds.forEach(item => {
          try {
            remindsStore.actions.create({
              chat: this.task.jid,
              fireAt: item.fireAt,
            })
          } catch (e) {
            console.error('Add remind error', e)
            this.remindsError = true
            if (e.details) {
              this.remindsErrorLabel = Object.values(e.details).join(' ')
            } else {
              this.remindsErrorLabel = this.$t('common.error')
            }
            // eslint-disable-next-line no-undef
            this.retryRemindFn = () => this.addRemind(date)
            this.remindsLoading = false
          }
        })
        this.remindsLoading = false
      }

      const goToTask = this.currentModalPayload?.goToTask

      if (this.task.description.length === 0) {
        this.report(this.$t('tasks.mustHaveDescription'))
        return
      }

      this.inProcess = true

      this.task.tags = this.task.tags.map(t => t.trim()).filter(t => t !== '')

      try {
        let r

        if (this.edit) {
          r = await tasksStore.actions.edit({
            jid: this.task.jid,
            task: {
              ...this.task,
              task_status: this.task.taskStatus,
            },
          })
        } else {
          const task2 = {
            ...this.task,
            items: (this.task.items ?? []).map(item => item.toJSON()),
            task_status: this.task.taskStatus,
            linked_messages: this.task.linkedMessages?.map(m => m.messageId),
            uploads: this.task.uploads?.map(u => u.uid),
          }

          const request = Object.fromEntries(
            Object.entries(task2).filter(
              ([k]) => this.task.changeableFields.includes(k),
            ),
          )

          if (this.unsavedReminds.length) {
            request.remind_at = this.unsavedReminds
              .map(r => r.fireAt)
              .join(',')
          }

          r = await tasksStore.actions.create(request)
        }

        uiStore.actions.clearSelectedMessages()

        if (this.files.length) {
          const uploaded = await this.sendFiles(r.jid)

          if (uploaded === this.files.length) {
            this.close(true)
          } else {
            this.filesNeedRetry = true
            this.createdChatId = r.jid
            this.processReport = this.$t('tasks.errorUploadingFiles')
          }
        } else {
          this.close(true)
        }

        if (goToTask) {
          uiStore.actions.toggleTaskDesk({ active: false })
          this.$router.push({
            name: 'Chat',
            params: {
              teamId: teamsStore.getters.currentTeam.uid,
              jid: r.jid,
            },
          })
        }
      } catch (e) {
        console.log(e)

        let message = ''
        if (e.details) {
          Object.keys(e.details).some(field => {
            return (message = e.details[field])
          })
        }
        message = message || e.error || JSON.stringify(e)

        this.report(message)
      }
    },
    async retryFiles () {
      const uploaded = await this.sendFiles(this.createdChatId)

      if (uploaded === this.files.length) {
        this.close(true)
      }
    },
    async sendFiles (chatId) {
      this.inProcess = true

      const promisifyRequest = data =>
        new Promise((resolve, reject) => {
          this.xhr = makeXHRequest({
            url: `/api/v3/teams/${teamsStore.state.currentTeamId}/messages/${chatId}/`,
            method: 'POST',
            data,
            resolve,
            reject,
          })
        })

      let uploaded = 0

      for (const index in this.files) {
        const file = this.files[index]
        const data = new window.FormData()
        data.append('file', file)
        try {
          if (
            this.uploadingStates[index] &&
            this.uploadingStates[index] === 2
          ) {
            uploaded++
            continue
          }
          this.uploadingStates[index] = 1
          await promisifyRequest(data)
          this.uploadingStates[index] = 2
          uploaded++
        } catch (e) {
          this.uploadingStates[index] = 3
          this.inProcess = false
          break
        }
        this.$forceUpdate()
      }

      return uploaded
    },
    sendGoal (msg, payload = {}) {
      window.goal('taskControls', {
        taskControls: (this.edit ? 'РЕДАКТИРОВАНИЕ — ' : 'СОЗДАНИЕ — ') + msg,
        ...payload,
      })
    },
    sendGoal2 (payload) {
      const target = 'taskControls'
      const action = this.edit ? 'Редактирование' : 'Создание'

      window.goal(target, {
        [target]: {
          [action]: payload,
        },
      })
    },
  },
}
