import { render, staticRenderFns } from "./LabeledContactList.vue?vue&type=template&id=1e6e8213&scoped=true&"
import script from "./LabeledContactList.vue?vue&type=script&lang=js&"
export * from "./LabeledContactList.vue?vue&type=script&lang=js&"
import style0 from "./LabeledContactList.vue?vue&type=style&index=0&id=1e6e8213&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e6e8213",
  null
  
)

export default component.exports
import {QIntersection,Scroll} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QIntersection})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Scroll})
