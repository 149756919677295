//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatBytes } from '@/utils'
import AdaptiveAvatar from '@/components/UI/AdaptiveAvatar'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'

export default {
  name: 'FileThumbnail',
  components: {
    'adaptive-image': AdaptiveAvatar,
    BaseSpinner,
  },
  props: {
    file: {
      type: window.File,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    loadingState: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      imagePreview: '',
    }
  },
  computed: {
    isImage () {
      return this.file.type.indexOf('image') >= 0 || this.imagePreview
    },
    ext () {
      const temp = this.file.name.split('.').pop()
      return temp.length > 5 ? '' : temp
    },
    size () {
      const { size } = this.file
      return size && formatBytes(size)
    },
  },
  created () {
    // if (!this.isImage) return

    const reader = new window.FileReader()
    reader.onload = e => this.loadImage(e.target.result)
    reader.readAsDataURL(this.file)
  },
  methods: {
    loadImage (src) {
      if (!src) return

      const image = new window.Image()
      image.onload = () => {
        this.imagePreview = image.src

        this.$emit('loaded', this.imagePreview)
      }
      image.src = src
    },
  },
}
