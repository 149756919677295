//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { throttle } from 'quasar'
import { getDefaultFuseOptions } from '@/utils'
import { multiSearch } from '@/utils/Common.ts'
import Fuse from 'fuse.js'
import LabeledContactList from '@/components/UI/LabeledContactList'
import { contactsStore } from '@/store'

let searchFn = null

export default {
  components: {
    'contact-list': LabeledContactList,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    filter: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      showDropdown: false,
      addContactFilter: '',
      selectedContactList: [],
      searchFnValid: false,
      searchResults: [],
      keyboardSelectIndex: 0,
      keyboardSelectToggle: false,
    }
  },
  computed: {
    filteredTeamMembers () {
      return contactsStore.getters.teamMembers.filter(this.filter || (() => true))
      // .filter(member => isMatch(member.displayName, this.addContactFilter))
      // .sort((a, b) => a.displayName > b.displayName ? 1 : -1)
    },
    teamMembers () {
      if (!this.addContactFilter.trim()) {
        return this.filteredTeamMembers
      }

      return this.searchResults
    },
    selectedContactName () {
      return this.value ? contactsStore.getters.contact(this.value).displayName : this.$t('tasks.noneSelected')
    },
    selectedContactIcon () {
      const contact = this.value && contactsStore.getters.contact(this.value)
      return contact && contactsStore.getters.contactIcon(contact.jid)
    },
  },
  watch: {
    filteredTeamMembers () {
      this.searchFnValid = false
    },
    addContactFilter () {
      this.search()
    },
  },
  mounted () {
    (this.$el.closest('.modal-frame-wrapper') || window).addEventListener('click', this.closeDropdown)

    this.search = throttle(this.search, 100)
  },
  beforeDestroy () {
    (this.$el.closest('.modal-frame-wrapper') || window).removeEventListener('click', this.closeDropdown)
  },
  methods: {
    search () {
      const options = getDefaultFuseOptions(['displayName'])
      if (!this.searchFnValid) {
        const fuse = new Fuse(this.filteredTeamMembers, options)
        searchFn = text => fuse.search(text)
        this.searchFnValid = true
      }

      // return searchFn(this.addContactFilter).map(i => i.item)
      this.searchResults = multiSearch(searchFn, this.addContactFilter)
    },
    removeContact (jid) {
      this.$emit('input', this.value.filter(item => item !== jid))
    },
    setAssignee (item) {
      this.$emit('input', item.jid)
      this.addContactFilter = ''
      this.closeDropdown()
      this.keyboardSelectToggle = false
    },
    openDropdown () {
      this.keyboardSelectIndex = 0
      this.showDropdown = true
    },
    closeDropdown () {
      this.keyboardSelectIndex = 0
      this.showDropdown = false
    },
    async toggleDropdown () {
      this.showDropdown
        ? this.closeDropdown()
        : this.openDropdown()
      await this.$nextTick()
      if (this.$refs.contactSearch) {
        this.$refs.contactSearch.focus()
      }
    },
    onInputChange () {
      this.keyboardSelectIndex = 0
    },
    onKeydown (event) {
      const key = event.key || event.keyCode // event.which is deprecated, see MDN. keyCode as fallback
      let manualKeyHandle = false
      let moveBy = 0
      switch (key) {
        case 'Enter':
        case 13: {
          this.keyboardSelectToggle = true
          break
        }
        case 'ArrowDown':
        case 40: {
          manualKeyHandle = true
          moveBy = 1
          break
        }
        case 'ArrowUp':
        case 38: {
          manualKeyHandle = true
          moveBy = -1
          break
        }
      }
      if (manualKeyHandle) event.preventDefault() // если prevent на весь элемент, то отключается ввод текста
      if (moveBy !== 0) this.keyboardSelectIndex += moveBy
    },
  },
}
