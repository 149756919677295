//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Fuse from 'fuse.js'
import { tagsStore } from '@/store'
import { throttle } from 'quasar'

const search = throttle((text, tags) => {
  const options = {
    shouldSort: true,
    threshold: 0.7,
    location: 0,
    distance: 100,
    minMatchCharLength: 1,
    keys: [
      'name',
    ],
  }
  const fuse = new Fuse(tags, options)
  return fuse.search(text).map(v => v.item)
}, 100)

let lastTagFetchTime = 0

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedIndex: 0,
      text: '',
    }
  },
  computed: {
    tags () {
      return tagsStore.state.tags
    },
    autocomplete () {
      return search(this.text, this.tags)
        .filter(tag => !this.value.includes(tag.name))
        .slice(0, 6)
    },
  },
  mounted () {
    if (this.isFocused) this.focus()
    if (Date.now() - lastTagFetchTime < 60 * 1000) {
      return
    }
    lastTagFetchTime = Date.now()
  },
  methods: {
    removeTag (tag) {
      this.$emit('input', this.value.filter(t => t !== tag))
    },
    tryPushTag (s) {
      s = (s || this.text).replace(/,/g, '').replace(/#/g, '').trim()
      if (s.length === 0) {
        return false
      }

      if (this.value.indexOf(s) !== -1) {
        this.$emit('input', [...this.value.filter(t => t !== s), s]) // двигаем существующий тег в конец
        return true
      }

      this.$emit('input', [...this.value, s.trim()])
      return true
    },
    addTag (tag) {
      tag = tag || this.autocomplete[this.selectedIndex]?.name
      if (!tag) return

      this.tryPushTag(tag)
      this.selectedIndex = 0
      this.text = ''
    },
    addFirstTag () {
      const tag = this.autocomplete[0]?.name
      if (tag) this.addTag(tag)
    },
    addCurrentTag () {
      const s = this.$refs.input.value ?? ''
      if (this.tryPushTag(s)) {
        this.selectedIndex = 0
        this.text = ''
      } else {
        this.text = s
      }
    },
    focus () {
      this.$refs.input && this.$refs.input.focus()
    },
    async onKeyup () {
      const s = this.$refs.input.value ?? ''
      await this.$nextTick()
      if ((s[s.length - 1] === ' ' || s[s.length - 1] === ',') && this.tryPushTag(s)) {
        this.selectedIndex = 0
        this.text = ''
      } else {
        this.text = s
      }
    },
    onKeydown (event) {
      const key = event.key || event.keyCode // event.which is deprecated, see MDN. keyCode as fallback

      if (key === 'Tab' || key === 9) {
        event.preventDefault()
        this.addFirstTag()
        return
      }

      if (key === 'Enter' || key === 13) {
        this.addTag()
        return
      }

      if (key === 'ArrowUp' || key === 38) {
        event.preventDefault()
        this.onKeydownUp()
        return
      }

      if (key === 'ArrowDown' || key === 40) {
        event.preventDefault()
        this.onKeydownDown()
      }
    },
    onKeydownDown () {
      this.selectedIndex++
      if (this.selectedIndex > this.autocomplete.length - 1) this.selectedIndex = 0
    },
    onKeydownUp () {
      this.selectedIndex--
      if (this.selectedIndex < 0) this.selectedIndex = this.autocomplete.length - 1
    },
  },
}
