//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import BaseSpinner from '@/components/UI/BaseSpinner.vue'
import { teamsStore } from '@/store'
import { date as quasarDate } from 'quasar'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

const { formatDate } = quasarDate

export default {
  components: {
    VueCtkDateTimePicker: () => ({
      component: import('vue-ctk-date-time-picker'),
      loading: BaseSpinner,
    }),
    'close-icon': createSVGComponent({ icon: 'close', size: 16, sizeStorage: 16 }),
  },
  // directives: { mask },
  props: {
    title: String,
    value: [String, Date, Object],
    anchor: [HTMLElement, Object],
    hideDeleteButton: Boolean,
    disableTo: {
      type: Date,
      default: () => new Date(Date.now() - 1000 * 60 * 60 * 24),
    },
    disableFrom: {
      type: Date,
      default: null,
    },
  },
  data () {
    return {
      date: null,
      windowPosition: null,
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale.substr(0, 2)
    },
    isMondayFirst () {
      const lang = this.$i18n.locale
      return lang === 'ru-RU' || lang === 'ru'
    },
    disabledDates () {
      const result = {}
      this.disableFrom && Object.assign(result, { from: this.disableFrom })
      this.disableTo && Object.assign(result, { to: this.disableTo })
      return result
    },
  },
  watch: {
    value: {
      handler (v) {
        this.date = v
          ? formatDate(v, 'YYYY-MM-DD\\THH:mm')
          : null
      },
      immediate: true,
    },
    date (date, prev) {
      this.$emit('raw-input', date)
      if (prev === null) {
        this.date = date.split('T')[0] + 'T' + (teamsStore.getters.currentTeam.defaultTaskDeadline || '23:55')
      }
    },
  },
  async mounted () {
    const rect = this.anchor && this.anchor.getBoundingClientRect && this.anchor.getBoundingClientRect()
    if (rect) {
      const top = Math.min(Math.max(240, (rect.top + rect.bottom) / 2), document.documentElement.clientHeight - 250) - 230
      const left = Math.min(Math.max(220, (rect.left + rect.right) / 2), document.documentElement.clientWidth - 220) - 220
      this.windowPosition = {
        top: `${top}px`,
        left: `${left}px`,
      }
    }

    if (this.value === null) {
      this.date = formatDate(new Date(), 'YYYY-MM-DD') + 'T' + (teamsStore.getters.currentTeam.defaultTaskDeadline || '23:55')
      await this.$nextTick()
      this.date = null
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    save () {
      if (this.date === null) {
        this.$emit('input', null)
        this.$emit('close')
        return
      }

      const tzo = -new Date().getTimezoneOffset()
      const dif = tzo >= 0 ? '+' : '-'

      const pad = function (num) {
        const norm = Math.floor(Math.abs(num))
        return (norm < 10 ? '0' : '') + norm
      }

      this.$emit('input', new Date(`${this.date}${dif + pad(tzo / 60) + ':' + pad(tzo % 60)}`).toISOString())
      this.$emit('close')
    },
    unset () {
      this.$emit('input', null)
      this.$emit('close')
    },
  },
}
