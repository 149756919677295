//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { contactsStore } from '@/store'

const itemHeight = 32
const itemDefaultCount = 4

export default {
  name: 'ObjectList',
  components: {
    'menu-icon': () => import('@/components/UI/icons/MenuIcon'),
  },
  props: {
    min: {
      type: Number,
      default: itemDefaultCount,
    },
    max: {
      type: Number,
      default: itemDefaultCount,
    },
    me: {
      type: String,
      default: '',
    },
    canChangeMembersStatus: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
    groupAdminPredicate: {
      type: Function,
      default: null,
    },
    forTagged: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    keyboardSelectIndex: {
      type: Number,
      default: 0,
    },
    keyboardSelectToggle: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedContact: null,
      itemMenuIndex: -1,
      scrollObserver: 0,
    }
  },
  computed: {
    listHeight () {
      this.$nextTick(() => {
        this.$refs.list['@@ScrollBar'].updateThumb()
      })

      if (this.data.length === 0) return 'auto'

      const currentHeight = this.data.length * itemHeight
      const minHeight = this.min * itemHeight
      const maxHeight = this.max * itemHeight
      return Math.max(minHeight, Math.min(currentHeight, maxHeight)) + 'px'
    },
    keyboardSelectIndexNormalized () {
      /* нужно для оверрайда prop keyboardSelectIndex, так как последний может быть меньше 0 и больше длинны текущего списка контактов */
      if (this.data.length < 1) return 0
      const moveBy = this.keyboardSelectIndex % this.data.length
      if (moveBy < 0) return this.data.length + moveBy
      return moveBy
    },
  },
  watch: {
    keyboardSelectToggle: function () {
      this.itemClick(this.data[this.keyboardSelectIndexNormalized])
    },
    keyboardSelectIndex: function (is) {
      if (is === null) return
      this.$nextTick(() => {
        const containerEl = this.$refs.list
        if (!containerEl) return

        const targetElement = containerEl.querySelector('.hover')
        if (!targetElement) return

        const { top, bottom } = containerEl.getBoundingClientRect()
        const { y, height } = targetElement.getBoundingClientRect()

        const targetYCenter = y + height / 2
        if (targetYCenter > top && targetYCenter < bottom) return

        const offset = targetYCenter >= bottom
          ? (y - bottom + height)
          : (targetYCenter <= top ? -(top - y) : 0)

        this.scrollObserver += offset
        containerEl.scrollTo(0, this.scrollObserver)
      })
    },
  },
  methods: {
    icon (jid) {
      return contactsStore.getters.contactIcon(jid)
    },
    menuClick (index, event) {
      const { target } = event
      target && target.focus()

      this.toggleMenu(index, 'focus')
    },
    itemClick (contact) {
      if (this.selectable) this.selectedContact = contact
      this.$emit('itemClick', contact)
    },
    toggleMenu (index, type) {
      if (type === 'focus') {
        this.itemMenuIndex = index
      } else {
        if (this.itemMenuIndex === index) this.itemMenuIndex = -1
      }
    },
    setContactStatus (contact) {
      this.$emit('clickStatusOption', { jid: contact.jid, isAdmin: this.groupAdminPredicate(contact) })
      this.itemMenuIndex = -1
    },
    excludeContact (contact) {
      this.$emit('clickExcludeOption', { jid: contact.jid })
      this.itemMenuIndex = -1
    },
  },
}
